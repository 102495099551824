<template>
  <div class="doc" v-html="htmlStr"></div>
</template>

<script>
const htmlStr = `
<p style=";text-align: center;font-family: Calibri;font-size: 14px;white-space: normal;vertical-align: baseline">
    <strong><span style="font-family: 宋体;font-size: 16px">用户服务说明</span></strong>
</p>
<p style=";text-align: right;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">&nbsp;&nbsp;&nbsp;</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">我们在此特别提醒，您在接受本服务之前已认真阅读、充分理解说明各条款，特别是其中所涉及的免除、减轻我们责任的条款、对用户权利限制条款、争议解决和法律适用等。其中，限制、免责条款可能以黑体加粗或加下划线的形式提示您重点注意。请您审慎阅读并选择接受或不接受本服务（未成年人应在监护人陪同下阅读），一旦您通过页面点击或其他方式确认即表示已同意说明。</span>
</p>
<p style=";font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px">
    <span style="font-family: 宋体;font-size: 16px">您进一步同意，</span><span style="font-family: 宋体;color: rgb(31, 35, 41);font-size: 16px">我们可能会定期修订和更新这些</span><span style="font-family: 宋体;color: rgb(31, 35, 41);font-size: 16px">服务</span><span style="font-family: 宋体;color: rgb(31, 35, 41);font-size: 16px">条款</span><span style="font-family: 宋体;color: rgb(31, 35, 41);font-size: 16px">，</span><span style="font-family: 宋体;color: rgb(31, 35, 41);font-size: 16px">所有更改将在我们发布时立即生效</span><span style="font-family: 宋体;color: rgb(31, 35, 41);font-size: 16px">，</span><span style="font-family: 宋体;font-size: 16px">无需另行单独通知您，若您无法同意变更修改后的服务内容，您有权停止使用相关服务。</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">您声明，在您同意接受本服务时，您具有法律规定的完全民事权利能力和民事行为能力，且能够独立承担民事责任；本服务内容不受您所属国家或地区的排斥。不具备前述条件的，您应立即停止使用本服务。</span>
</p>
<p style="margin: 10px 0 0;text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;vertical-align: baseline">
    <strong><span style="font-family: 宋体;font-size: 16px">关于本服务的开通与注销</span></strong>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">（一）账户开立</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">我们可根据用户需要及《中华人民共和国数据安全法</span><span style="font-family: 宋体;font-size: 16px">》《</span><span style="font-family: 宋体;font-size: 16px">中国人民共和国网络安全法》相关要求，为用户开设数字钱包账户，并提供相关必要的数据存储、数据管理、信息查询、技术支持等服务，具体以我们实际提供的为准。主要流程包括：</span>
</p>
<p style=";font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 28px">
    <span style="font-family: 宋体;font-size: 16px">1. 您需按照平台网站页面提示如实填写、提供主体基本信息，您应保证提交资料的准确性、真实性、完整性、合法性，</span><span style="font-family: 宋体;color: rgb(31, 35, 41);font-size: 16px">且有义务维持并及时更新您的资料。</span>
</p>
<p style=";font-family: Calibri;font-size: 14px;white-space: normal">
    <span style="font-family: 宋体;font-size: 16px">&nbsp;</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">2. 我们有权按照国家实名制管理要求，登记并采取有效措施验证用户身份信息，建立用户唯一识别编码，确保有效核实用户身份及其真实意愿后，为其开立数字钱包账户。</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 28px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">（二）您同意遵守所有与服务相关之国内国际法律法规的规定和本协议的约定，并同意不使用他人的身份登记注册或者干扰他人使用服务。</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">（三）您应妥善保管您的钱包账号和相关密码，并确保您的钱包账户和相关密码不出租、出借、出售、购买账户。</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">（四）您有权按照我们规定的销户流程申请注销数字钱包账户。您了解并同意，您的数字钱包账户被注销，即表明我们与您之间的本服务已终止，您可以行使数据可携权，亦可将数据删除。</span>
</p>
<p style="margin: 10px 0 0;text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;vertical-align: baseline">
    <strong><span style="font-family: 宋体;font-size: 16px">关于本服务的使用</span></strong>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">（一）服务说明</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">1. 一旦您注册成为本服务之用户，我们将遵照您的指示并根据我们的流程规定和要求提供平台相关服务。</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">2. 用户向平台提供的数据所有权归属方为用户本人，我们将按照您的授权要求处理您的数据，不会将您的数据用于本服务约定的目的以外的事项。本服务项下约定的授权行为为不可撤销之授权。</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">&nbsp;</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">&nbsp;</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">&nbsp;</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">&nbsp;</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">（二）本服务功能的调整</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">1. 您同意我们为保证自主业务经营权可以暂时提供部分服务功能，或于将来暂停部分服务功能或开通新的服务功能，当任何功能减少或者增加或者变化时，若您不同意对应服务内容的变更或修改，您有权停止使用相关服务功能。</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">（三）本服务的暂停、中止或终止</span>
</p>
<p style=";font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 28px">
    <span style="font-family: 宋体;font-size: 16px">1. </span><span style="font-family: 宋体;font-size: 16px">您同意，如</span><span style="font-family: 宋体;font-size: 16px">您违反相关法律法规，</span><span style="font-family: 宋体;font-size: 16px">我们在接到人民法院或政府监管及执法部门通知时</span><span style="font-family: 宋体;font-size: 16px">，</span><span style="font-family: 宋体;font-size: 16px">本系统有权限制、中止、冻结或终止您对账号的使用，且根据</span><span style="font-family: 宋体;font-size: 16px">生效的法院判决或政府监管及执法部门出具的书面文件决定</span><span style="font-family: 宋体;font-size: 16px">是否</span><span style="font-family: 宋体;font-size: 16px">能</span><span style="font-family: 宋体;font-size: 16px">恢</span><span style="font-family: 宋体;color: rgb(31, 35, 41);font-size: 16px">复您对账号的使用，而由此产生的损失由您自行承担。</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">2. 您同意，发生下列情形之一的，我们无需进行事先通知即可暂停、中止或终止向您提供本服务。</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px"><span style="font-family:宋体">（</span> <span style="font-family:宋体">1 ）您提供的身份基本信息不真实，且未能提供合理说明及证明；</span></span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px"><span style="font-family:宋体">（</span> <span style="font-family:宋体">2 ）您违反相关法律、法规、规章及政府规范性文件或本协议约定；</span></span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px"><span style="font-family:宋体">（</span> <span style="font-family:宋体">3 ）您未按照本协议约定足额付费；</span></span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px"><span style="font-family:宋体">（</span> <span style="font-family:宋体">4 ）数据管理、公安等政府机关向我们发出要求；</span></span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px"><span style="font-family:宋体">（</span> <span style="font-family:宋体">5 ）数据存在违法国家法律法规规定的安全隐患。</span></span>
</p>
<p style="margin: 10px 0 0;text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;vertical-align: baseline">
    <strong><span style="font-family: 宋体;font-size: 16px">用户的基本权利和义务</span></strong>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">1. 用户依法享有知悉其购买、使用数字钱包产品，接受数字钱包及相关数据资产交易服务的权利。</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">2. 用户向我们提供有关资料信息时，有权知悉其所提供信息的使用目的，使用范围、安全保护措施以及未提供真实信息的后果。用户享有账户资料、交易信息受保护的权利。</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">3. 用户在购买数字钱包产品或接受相关服务之前，有权知悉我们所提供的产品或服务风险信息。</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">4. 用户有权进行数字钱包账户的申请、注销、携带数据、清除数据。</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">5. 用户使用我们的服务过程中，可通过平台网站的在线客服或客户服务电话与我们联系，并获取相关的技术和服务支持。</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">6. 用户不得把数字钱包账户用于本协议约定范围以外的其他用途，也不得出租、出借、转让给第三方使用。</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">7. 用户应妥善保管其在我们开立的账户及其密码，在使用我们的服务时，应确保自身在数字钱包上管理的数据的合法性、真实性、有效性和完整性。</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">8. 用户不得利用我们的服务从事任何非法行为，不得违反中国法律、法规、规章、规范性文件及政策，不得侵犯他人的合法权益，不得为任何非法目的而使用我们数字钱包服务，不得从事危害计算机信息网络安全的行为。如用户是中国大陆以外的用户，还需同时遵守其所属国家或地区的法律。</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">9. 用户不得对我们的平台服务系统和程序采取反向工程手段进行破解，不得对上述系统和程序（包括但不限于源程序、目标程序、软件文档、运行在本地电脑内存中的数据、客户端至服务器端的数据、服务器数据等）进行复制、修改、编译、整合和篡改，不得修改或增加我们数字钱包服务系统的原有功能。</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">10.</span><span style="font-family: 宋体;font-size: 16px">&nbsp;</span><span style="font-family: 宋体;font-size: 16px">因用户主观过错或者过失导致的任何损失由用户自行承担，该过错或者过失包括但不限于：不按照交易提示操作，未及时进行交易操作，泄露个人信息、泄漏密码，密码被他人破解，使用的计算机被他人侵入等。</span>
</p>
<p style="margin: 10px 0 0;text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;vertical-align: baseline">
    <strong><span style="font-family: 宋体;font-size: 16px">我们的基本权利和义务</span></strong>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">1. 我们负责平台系统的建设、运行和管理，确保该系统的安全性。</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">2. 我们对用户身份资料及数据信息进行加密存储。</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">3. 我们应为用户提供本服务约定的数字钱包及相关交易服务的各项功能，并可按照约定向用户收取相关服务费用。</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">4. 我们应以明确的格式、内容、语言，对其提供的数字钱包产品或服务，依法向用户进行全面、充分的信息披露和风险提示。</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">5</span><span style="font-family: 宋体;font-size: 16px">. 我们充分尊重用户自主选择权，不强迫用户使用我们提供的数字钱包服务，不阻碍用户使用其他机构提供的支付服务。</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">6</span><span style="font-family: 宋体;font-size: 16px">. 我们应当公平展示用户可选用的各种服务功能和服务套餐，不得附加不合理的条件。</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">7</span><span style="font-family: 宋体;font-size: 16px">. 为提供更高效和优质的服务，我们可根据运营需要对服务系统进行升级，因升级需要暂停或中止提供服务的，我们应提前在网站显著位置进行公告，并预告恢复日期。</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">8</span><span style="font-family: 宋体;font-size: 16px">. 我们有权根据市场变化适时调整相应的服务费用，但应依照数据监管部门的相关规定提前通知用户。</span><span style="font-family: 宋体;font-size: 16px">&nbsp;</span>
</p>
<p style="margin: 10px 0 0;text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;vertical-align: baseline">
    <strong><span style="font-family: 宋体;font-size: 16px">服务费用</span></strong>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">我们如对用户使用数字钱包等相关服务收取服务费用的，应在本服务或平台网站对费用标准进行明确。详细各项服务费用如没有在企业数字钱包网站上所展示，则表示目前免费。我们保留制定与不时调整服务费用的权利。</span>
</p>
<p style="margin: 10px 0 0;text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;vertical-align: baseline">
    <strong><span style="font-family: 宋体;font-size: 16px">资金结算方式</span></strong><span style="font-family: 宋体;font-size: 16px">：通过微信或支付宝的方式进行结算。</span>
</p>
<p style="margin: 10px 0 0;text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;vertical-align: baseline">
    <strong><span style="font-family: 宋体;font-size: 16px">知识产权、保密条款</span></strong>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">（一）知识产权</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">1. 在本服务签订和履行过程中，我们对平台服务相关的系统、程序、文档、商业标识、产品和服务等享有知识产权，这些内容受法律保护。</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">2. 用户因履行本服务需要使用以上权利的，应经我们明确的书面许可。我们的书面授权不构成任何形式的权利转让。</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">（二）保密条款</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">1. 在签署或履行本服务过程中，我们与用户双方对了解或接触到的涉及对方的业务、技术、财务及其他方面未公开的资料和信息等商业秘密（下称“保密信息”），均应保守秘密。</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">2</span><span style="font-family: 宋体;font-size: 16px">. 上述保密义务不因本服务的终止而失效。</span>
</p>
<p style="margin: 10px 0 0;text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;vertical-align: baseline">
    <strong><span style="font-family: 宋体;font-size: 16px">风险及免责事由</span></strong>
</p>
<p style="font-size: medium;white-space: normal">
    <span style="font-family: 宋体;color: rgb(31, 35, 41);font-size: 16px">1．</span><span style="font-family: 宋体;color: rgb(31, 35, 41);font-size: 16px">您理解并同意：为了向您提供有效的服务，在使用本系统过程中可能产生数据流量的费用，您需自行向运营商了解相关资费信息，并自行承担相关费用。您在使用本系统提供的服务时，须自行承担如下本系统不可掌控的风险内容，因您的过错导致的任何损失由您自行承担。您理解并同意，因业务发展需要，本系统有权在提前告知的前提下，对本系统的全部或部分服务内容进行变更、暂停、限制、终止或撤销。</span>
</p>
<p style="font-size: medium;white-space: normal">
    <span style="font-family: 宋体;color: rgb(31, 35, 41);font-size: 16px">2.</span><span style="font-family: 宋体;color: rgb(31, 35, 41);font-size: 16px"><span style="font-family:宋体">由于互联网本身所具有的不稳定性，本系统无法保证服务不会中断。系统因有关状况无法正常运作，使用户无法使用服务或使用服务受到任何影响时，本系统对用户或第三方不负任何责任，前述状况包括但不限于：（</span>1）本系统停机维护期间。（2）电信设备出现故障不能进行数据传输的。（3）由于黑客攻击、网络供应商技术调整或故障、网站升级、银行方面的问题等原因而造成的服务中断或延迟。（4）因不可抗力之因素，造成系统障碍不能执行业务的。</span>
</p>
<p style="margin: 10px 0 0;text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;vertical-align: baseline">
    <strong><span style="font-family: 宋体;font-size: 16px">适用法律及争议解决</span></strong>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">1. 本服务的解释、适用、争议解决等一切事宜，均适用中华人民共和国法律（香港特别行政区、澳门特别行政区和台湾地区的法律除外），冲突法规则除外。</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">2. 因本服务产生任何纠纷时，各方应友好协商，协商不成的，各方应将争议提交有管辖权的人民法院诉讼解决。</span>
</p>
<p style="margin: 10px 0 0;text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;vertical-align: baseline">
    <strong><span style="font-family: 宋体;font-size: 16px">有效期及其他条款</span></strong>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal;text-indent: 32px;vertical-align: baseline">
    <span style="font-family: 宋体;font-size: 16px">本服务自用户通过平台网站或平台勾选或点击接受并同意之日起生效，有效期至本服务被解除或终止之日。本服务终止并不免除用户根据本服务或其他有关协议、规则所应承担的义务和责任。本服务条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。</span><span style="font-family: 宋体;font-size: 16px">&nbsp;</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal">
    <span style="font-family: 宋体;font-size: 16px">&nbsp;</span>
</p>
<p style=";text-align: justify;font-family: Calibri;font-size: 14px;white-space: normal">
    <span style="font-family: 宋体;font-size: 16px">&nbsp;</span>
</p>
<p>
    <br/>
</p>
`
  export default {
    data() {
      return {
        htmlStr
      }
    }
  }
</script>

<style scoped>
.doc {
  padding: 15px;
}
</style>